<script lang="ts">
  import logo from "$lib/assets/logo.svg";
  import MarketingFooterSocial from "./MarketingFooterSocial.svelte";

  const useCases = [
    { name: "Language Model Inference", link: "/use-cases/language-models" },
    { name: "Image, Video & 3D", link: "/use-cases/image-video-3d" },
    { name: "Audio Processing", link: "/use-cases/audio" },
    { name: "Fine-Tuning", link: "/use-cases/fine-tuning" },
    { name: "Job Queues & Batch Processing", link: "/use-cases/job-queues" },
    { name: "Sandboxing Code", link: "/use-cases/sandboxes" },
  ];

  const popularExamples = [
    {
      name: "Serve LLM APIs with vLLM",
      link: "/docs/examples/vllm_inference",
    },
    {
      name: "Create Custom Art of Your Pet",
      link: "/docs/examples/dreambooth_app",
    },
    {
      name: "Analyze Parquet files from S3 with DuckDB",
      link: "/docs/examples/s3_bucket_mount",
    },
    {
      name: "Run hundreds of LoRAs from one app",
      link: "/docs/examples/cloud_bucket_mount_loras",
    },
    {
      name: "Replace your CEO with an LLM",
      link: "/docs/examples/slack-finetune",
    },
  ];

  const resources = [
    { name: "Documentation", link: "/docs/guide" },
    { name: "Pricing", link: "/pricing" },
    { name: "Slack Community", link: "/slack" },
    { name: "Security & Privacy", link: "/docs/guide/security" },
    { name: "Articles", link: "/articles" },
    { name: "GPU Glossary", link: "/gpu-glossary" },
  ];

  const company = [
    { name: "About", link: "/company" },
    { name: "Blog", link: "/blog" },
    { name: "Careers", link: "/careers" },
    { name: "Privacy Policy", link: "/legal/privacy-policy" },
    { name: "Terms", link: "/legal/terms" },
  ];
</script>

<div class="marketing-container py-14">
  <div class="grid lg:grid-cols-2 gap-5">
    <div class="flex flex-col items-start">
      <a href="/" class="mb-12">
        <img src={logo} alt="Modal logo" class="h-12" />
      </a>
      <div class="mt-auto hidden lg:block">
        <MarketingFooterSocial />
      </div>
    </div>
    <div class="grid sm:grid-cols-3 gap-x-5 text-sm">
      <div class="idxsec sm:col-span-2">
        <p>Use Cases</p>
        {#each useCases as item}
          <p><a class="idxlink" href={item.link}>{item.name}</a></p>
        {/each}
      </div>
      <div class="idxsec pt-16 sm:pt-0">
        <p>Resources</p>
        {#each resources as item}
          <p><a class="idxlink" href={item.link}>{item.name}</a></p>
        {/each}
      </div>
      <div class="idxsec pt-16 sm:col-span-2">
        <p>Popular Examples</p>
        {#each popularExamples as item}
          <p><a class="idxlink" href={item.link}>{item.name}</a></p>
        {/each}
      </div>
      <div class="idxsec pt-16">
        <p>Company</p>
        {#each company as item}
          <p><a class="idxlink" href={item.link}>{item.name}</a></p>
        {/each}
      </div>
    </div>
  </div>

  <div class="pt-16 lg:hidden">
    <MarketingFooterSocial />
  </div>
</div>

<style lang="postcss">
  .idxsec {
    @apply border-l border-light-green/30 pl-5 space-y-2.5 max-w-[28ch];
  }

  .idxlink {
    @apply text-light-green/60 hover:text-light-green transition-colors;
  }
</style>
