<script lang="ts">
  import { page } from "$app/stores";

  import {
    type DotLottie,
    DotLottieSvelte,
  } from "@lottiefiles/dotlottie-svelte";
  import clsx from "clsx";
  import { Hamburger } from "svelte-hamburgers";

  import logoLottieSrc from "$lib/assets/logo.lottie";
  import LoginSignupButtons from "$lib/ui/common/LoginSignupButtons.svelte";
  import MobileMenuDropdown from "$lib/ui/marketing/MobileMenuDropdown.svelte";
  import UseCaseDropdown from "$lib/ui/marketing/UseCaseDropdown.svelte";

  let useCaseDropdownOpen = false;
  let mobileOpen = false;
  let dropdownTimeout: ReturnType<typeof setTimeout> | null = null;
  let logoLottie: DotLottie | null = null;

  const links = [
    { href: "/pricing", name: "Pricing" },
    { href: "/customers", name: "Customers" },
    { href: "/blog", name: "Blog" },
    { href: "/docs", name: "Docs" },
    { href: "/company", name: "Company", cls: "hidden lg:block" },
  ];

  function showUseCaseDropdown() {
    if (dropdownTimeout) {
      clearTimeout(dropdownTimeout);
    }
    useCaseDropdownOpen = true;
  }

  function hideUseCaseDropdownDelayed() {
    dropdownTimeout = setTimeout(() => {
      useCaseDropdownOpen = false;
    }, 200);
  }
</script>

<div class="sticky top-0 md:pt-6 pt-4 z-10">
  <div class="marketing-container">
    <nav
      class="flex justify-between items-center bg-dark-gray p-2.5 relative transition-all duration-300"
      class:rounded-3xl={!useCaseDropdownOpen}
      class:rounded-t-xl={useCaseDropdownOpen}
    >
      <div class="pl-8">
        <a href="/" on:click={() => (mobileOpen = false)}>
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            class="h-7 w-20 scale-[150%]"
            on:mouseenter={() => logoLottie?.play()}
          >
            <DotLottieSvelte
              src={logoLottieSrc}
              dotLottieRefCallback={(ref) => {
                logoLottie = ref;
              }}
              renderConfig={{
                devicePixelRatio: 2,
              }}
              segment={[25, 83]}
            />
          </div>
        </a>
      </div>

      <div class="hidden md:flex items-center text-sm gap-7 xl:gap-10">
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          on:mouseenter={showUseCaseDropdown}
          on:mouseleave={hideUseCaseDropdownDelayed}
          class="hover:text-brand-green cursor-default -m-3 p-3"
          class:text-brand-green={$page.url.pathname.startsWith("/use-cases")}
        >
          Use Cases
          {#if useCaseDropdownOpen}
            <UseCaseDropdown on:close={() => (useCaseDropdownOpen = false)} />
          {/if}
        </div>
        {#each links as { href, name, cls }}
          <a
            {href}
            class={clsx(
              "hover:text-brand-green",
              $page.url.pathname === href && "text-brand-green",
              cls,
            )}
          >
            {name}
          </a>
        {/each}
      </div>

      <div class="hidden md:flex items-center text-sm gap-7">
        <LoginSignupButtons />
      </div>

      <!-- Mobile animated hamburger menu -->
      <div class="mr-2 md:hidden text-light-green">
        <Hamburger
          bind:open={mobileOpen}
          --color="currentColor"
          --layer-height="1.5px"
          --layer-width="18px"
          --layer-spacing="5px"
          --padding="4px"
        />
      </div>
    </nav>
  </div>

  <MobileMenuDropdown
    bind:open={mobileOpen}
    {links}
    includeUseCases
    paddingTop="72px"
  />
</div>
